
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.top-panel {
  flex:3 ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.button {
  margin-top:0;
}
.bottom-panel {
  flex: 1;
  /* background-color: #2ecc71;  */
  display: flex;
  justify-content: space-between; /* To position the images at the start and end of the row */
}

.start-img,
.end-img {
  width: 230px; /* Adjust width as needed */
  height: auto; /* To maintain aspect ratio */
  margin-bottom: 8px;
}
.radio-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  transition: transform 0.3s;
}
.radio-card:hover {
  transform: translateY(-5px);
}

.card-content-wrapper {
  position: relative;
  width: 100%;
}
.check-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 50%;
  transition: background-color 0.3s;
}
input[type='radio'] {
  display: none;
}

input[type='radio']:checked + .card-content-wrapper .check-icon {
  background-color: white;
}

.card-content {
  text-align: center;
  padding: 20px;
}

.card-content img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.card-content h4,
.card-content h5 {
  margin: 5px 0;
}


/* Media query for laptops */
@media screen and (min-width: 1024px) {
  .container {
    font-size: 16px; /* Adjust font size for larger screens */
  }
}

/* Media query for tablets */
@media screen and (max-width: 768px) {
  .container {
    font-size: 14px; /* Adjust font size for tablets */
  }
}

/* Media query for phones */
@media screen and (max-width: 480px) {
  .container {
    font-size: 12px; /* Adjust font size for phones */
  }
}